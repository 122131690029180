// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // "key_secret": "",
  // rzpKey: "",
  "key_secret": "uPaxG95HIetyom59U8AKHMuH",
  rzpKey: "rzp_live_lltNayH4Jef8Qt",

   apiUrl: 'https://api.uttarakhandherbs.com/api/user/'
  // apiUrl: 'http://4186-2405-201-4003-70ce-b467-8e00-5513-bcb1.in.ngrok.io/api/user/',
  // https://4186-2405-201-4003-70ce-b467-8e00-5513-bcb1.in.ngrok.io/
  // apiUrl:'https://4186-2405-201-4003-70ce-b467-8e00-5513-bcb1.in.ngrok.io/'
  // apiUrl: 'http://13.126.150.139:5153/api/user/'
  //apiUrl: 'http://localhost:5153/api/user/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.