import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './components/alert/alert.component';
import { LoaderModalComponent } from './loader-modal/loader-modal.component';
const cms = [ LoaderModalComponent]

@NgModule({
  declarations: [AlertComponent,LoaderModalComponent],
  imports: [
    CommonModule
  ],
  exports: [...cms,AlertComponent]
})
export class SharedModule { }
