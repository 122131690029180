import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from "rxjs";
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private auth = new BehaviorSubject(false);
  private user = new BehaviorSubject(<any>null);
  private categories = new BehaviorSubject(<any>null);
  emittedValue = new BehaviorSubject(false);

  constructor(private http: HttpClient) { }

  // output to Auth Guard and Other Component
  getAuth(): Observable<boolean> {
    return this.auth.asObservable();
  }

  getUser(): Observable<any> {
    return this.user.asObservable();
  }

  // If login is true
  updateUser(authState: boolean, user: any) {
    this.auth.next(authState);
    this.user.next(user);
  }

  updateCategories(data: any) {
    this.categories.next(data);
  }

  getCategories(): Observable<any> {
    return this.categories.asObservable();
  }

  removeFromCart(cartData: any) {
    const url = `${environment.apiUrl}cart/deleteproduct`;
    return this.http.put(url, cartData).pipe(
      tap(
        (res: any) => {
          if (res.meta.status) {
            this.emittedValue.next(true);
          }
        }
      )
    );
  }

  addToCart(cartData: any) {
    const url = `${environment.apiUrl}cart/add`;
    return this.http.post(url, cartData).pipe(
      tap((res: any) => {
        if (res.meta.status) {
          this.emittedValue.next(true);
        }
      })
    );
  }

  emitCardData(): Observable<boolean> {
    return this.emittedValue.asObservable();
  }

  updateCartQuantity(cartData: any) {
    const url = `${environment.apiUrl}cart/update/quantity`;
    return this.http.put(url, cartData).pipe(
      tap(
        (res: any) => {
          if (res.meta.status) {
            this.emittedValue.next(true);
          }
        }
      )
    );
  }

  getCartProducts(pincode: any) {
    var url = `${environment.apiUrl}cart/list`;
    if(pincode) {
      url = `${environment.apiUrl}cart/list?pincode=${pincode}`;
    }
    return this.http.get(url);
  }

}
